import '../assets/css/login.css';

import Formsy from 'formsy-react';
import { Link, navigate } from 'gatsby';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import ButtonLoader from '../components/buttonLoader';
import ErrrorHandel from '../components/ErrrorHandel';
import MyInput from '../components/MyInput';
import bottomLine from '../images/login-line.svg';

//import Logo from '../images/logo-white.png';
import { setCredentials } from './../core/auth';
import * as actionCreators from './../store/actions';

import sideImg from '../images/gray/logincar.png';
import Layout from "../components/layout"
import SEO from "../components/seo"
import userIcon from "../images/avatar.svg"
import lockIcon from "../images/lock.svg"
import Footer from '../components/footer';
import lineImage from "../images/line2.png"

class LoginPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            canSubmit: false,
            loginerr:null,
            dealertoken:null,
        };
    }
    componentDidMount() {
        this.getDealerToken();
    }
    getDealerToken = () => {
        if(global.dealertoken){
            this.setState({ dealertoken: global.dealertoken })
            return global.dealertoken;
        } else {
            let data = this.props.location.search;
            if (data !== '') {
                const search = data.substring(1);
                let param = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
                let dt = param.dealertoken ? param.dealertoken : null;
                this.setState({ dealertoken: dt });
                return dt;
            } else {
                this.setState({ dealertoken: null });
                return null;
            }
        }
    }

    disableButton = () => {
        this.setState({ canSubmit: false });
    }

    enableButton = () => {
        this.setState({ canSubmit: true });
    }

    submit = async(model) => {
        const { showButtonLoader, hideButtonLoader, loginResponse } = this.props;
        showButtonLoader()
        let uri = process.env.GATSBY_APP_API_BASE_URL+'rest/login'+(global.dealertoken?'/?dealertoken='+global.dealertoken:'');
        await fetch(uri,{
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                email: model.email,
                password: model.password
            })
        })
        .then(res => res.json())
        .then((res) => {
            if(res.status === 1){
                setCredentials(res.me);
                loginResponse(res.me);
                navigate("/userprofile"+(global.dealertoken?'/?dealertoken='+global.dealertoken:''));
            } else {
                this.setState({ loginerr: "Incorrect username or password" });
            }
            hideButtonLoader();
        });

        
    }

    close() {
        if (this.props.location && this.props.location.state && this.props.location.state.prevPath) {
            navigate(this.props.location.state.prevPath+(global.dealertoken?'/?dealertoken='+global.dealertoken:''));
        } else {
            navigate("/"+(global.dealertoken?'/?dealertoken='+global.dealertoken:''));
        }
    }

    render() {

        const { canSubmit,loginerr,dealertoken } = this.state;
        const { buttonLoader } = this.props;
        
        return (
            <Layout layoutType="main">
            <SEO title="Login" keywords={[`servicecontract.com`, `service contracts`, `Extended warranty`]} />

            <div className={dealertoken?"login-container grayish":"login-container"}>
                {/* <span className="close-btn" onClick={() => { this.close() }}> <img src={close} alt="" /></span> */}
                <div className="left">
                    <img className="sideimg" src={sideImg} alt="img" />
                </div>
                <div className="right">


                    <Formsy ref="loginForm" onValidSubmit={this.submit} onValid={this.enableButton} onInvalid={this.disableButton}>
                        <ErrrorHandel formName={'loginForm'} self={this} />
                        <div className="sign-in-container">
                            <h2 className="title">Sign in</h2>
                            {/* <p className="subtitle">Please sign in to continue.</p> */}
                            <div className="form-group error">
                                <img className="txticon" src={userIcon} alt="user" />
                                <MyInput
                                    name="email"
                                    id="username"
                                    placeholder="Username"
                                    validations="isEmail"
                                    validationError="Please enter valid email"
                                    className="form-control"
                                    required
                                />
                            </div>
                            <div className="form-group error">
                            <img className="txticon" src={lockIcon} alt="password" />
                                <MyInput
                                    name="password"
                                    type="password"
                                    id="password"
                                    placeholder="Password"
                                    className="form-control"
                                    validations={{maxLength: 100,minLength: 8}}
                                    validationError="Please enter correct password."
                                    required
                                />
                            </div>
                            
                            <div className="form-group error">
                            <Link className="forgot-pass" to={"/forgotpass"+(dealertoken?'/?dealertoken='+dealertoken:'')}>Forgot password?</Link>
                            </div>

                            <div className="error-txt">{loginerr}</div>
                            <button className={!canSubmit ? 'btn disabled' : 'btn'} disabled={!canSubmit} type="submit">
                                <ButtonLoader isLoading={buttonLoader} label={'SIGN IN'} />
                            </button>
                            
                        </div>
                    </Formsy>
                    {!dealertoken &&
                    <div className="login-line">
                        <img src={bottomLine} alt="line" />
                    </div>
                    }
                </div>
            </div>
            {!dealertoken &&
            <>
                <div className="break-section">
                    <img src={lineImage} alt="line" />
                </div>
                <Footer />
            </>
            } 
            </Layout>
        );
    }
}

const mapStateToProps = state => {
    return {
        pageLoader: state.primary.pageLoader,
        buttonLoader: state.primary.buttonLoader,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        showLoader: () => dispatch(actionCreators.showLoader()),
        showButtonLoader: () => dispatch(actionCreators.showButtonLoader()),
        hideLoader: () => dispatch(actionCreators.hideLoader()),
        hideButtonLoader: () => dispatch(actionCreators.hideButtonLoader()),
        loginResponse: (response) => dispatch(actionCreators.loginResponse(response))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);